import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Row from "../components/row"
import Arrow from "../components/arrow"
import Spacer from "../components/spacer"

import l from "../components/layout.module.scss" // layout styles

const PrivacyPage = () => {
  const { title, content } = useStaticQuery(graphql`
    query {
      wordpressPage(slug: { eq: "privacyverklaring" }) {
        title
        content
      }
    }
  `).wordpressPage
  return (
    <Layout>
      <Row backgroundColor="#f2f2f2">
        <div
          style={{ paddingBottom: 0 }}
          className={[l.col1, l.title_section].join(" ")}
        >
          <h1 className={l.title}>{title}</h1>
          <h3 className={l.sub_title}>Versie mei 2020</h3>
        </div>
        <Spacer>
          <Arrow rotation="90deg" fontSize="18px" fontWeight="bold">
            Beleid
          </Arrow>
        </Spacer>
      </Row>
      <Row>
        <div
          className={l.custom_content}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      </Row>
    </Layout>
  )
}

export default PrivacyPage
